<template>
  <div>
    <div @click="emitDetail(cardItem.is_child)" class="container">
      <div class="img_content">
        <!-- <van-image :src="cardItem.image" fit="contain" /> -->
        <img class="main_img" :src="cardItem.image" alt="" />
      </div>
      <div class="right_content">
        <div class="right_title">
          {{ cardItem.store_name }}
        </div>
        <div class="right_desc">{{ cardItem.store_info }}</div>
        <div class="right_price">
          <div class="">
            <span class="text-xs leading-0">￥</span>
            <span class="text-sm leading-0">{{ cardItem.price_slot }}</span>
          </div>
          <s>
            <span class="leading-[10px] text-[10px] text-[#ababab]">原价</span>
            <span class="price_icon">￥</span>
            <span class="price_count">{{ cardItem.price }}</span>
          </s>
        </div>
        <div class="color_box">
          <div v-for="(item, index) in cardItem.colorInfo" :key="index" class="color_item" :style="{ 'background-color': item }"></div>
        </div>
        <div class="bg-[#3F3F3F] rounded-2xl z-999 px-4 h-5 flex justify-center items-center absolute bottom-[14px] right-3">
          <span class="text-[#FFF] text-xs">选择</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    cardItem: {
      type: Object,
      default: {},
    },
  },
  methods: {
    emitDetail(item) {
      this.$emit("itemDetail", {
        is_child: item,
        item: this.cardItem,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  height: 139px;
  width: 100%;
  overflow: hidden;
  background-color: #e3e4e5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  .img_content {
    height: 100%;
    // display: flex;
    // align-items: center;
    // height: 134px;
    width: 139px;
    .main_img {
      height: 100%;
      // width: 139px;
      object-fit: cover;
    }
  }
  .right_content {
    box-sizing: border-box;
    position: relative;
    padding: 12px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    .right_title {
      color: #3f3f3f;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .right_desc {
      font-size: 12px;
      width: 169px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-weight: 400;
      line-height: 20px;
      color: #ababab;
    }
    .right_price {
      white-space: nowrap;
      position: absolute;
      left: 12px;
      bottom: 12px;
      color: #3f3f3f;
      font-weight: 600;
      line-height: 20px;

      .price_icon {
        line-height: 8px;
        color: #ababab;
        font-size: 10px;
      }
      .price_count {
        line-height: 10px;
        color: #ababab;
        font-size: 10px;
      }
    }
  }
  .color_box {
    width: 10px;
    position: absolute;
    top: 2px;
    right: 12px;
    display: flex;
    max-height: 110px;
    flex-direction: column;
    .color_item {
      display: block;
      margin-top: 9px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }
}
</style>
