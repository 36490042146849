<template>
  <div>
    <ip-series-item
      v-for="(item, index) in seriesList"
      :key="index"
      :seriesItem="item"
    ></ip-series-item>
  </div>
</template>

<script>
import ipSeriesItem from "@/components/content/ipSeriesInfo/ipSeriesItem.vue";
export default {
  name: "",
  components: {
    ipSeriesItem
  },
  props: {
    seriesList: {
      type: Array,
      require: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
