<template>
  <div class="vectors flex absolute -z-0 w-full h-full justify-between">
    <div class="vector h-full"></div>
    <div class="vector h-full"></div>
    <div class="vector h-full"></div>
    <div class="vector h-full"></div>
    <div class="vector h-full"></div>
    <div class="vector h-full"></div>
    <div class="vector h-full"></div>
  </div>
</template>
