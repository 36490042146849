<template>
  <div>
    <div class="w-full">
      <div class="w-full h-full flex justify-between items-center pt-3 px-3">
        <van-field class="bg-[#F5F5F5] align-middle rounded-[6px] w-[309px] h-[38px] leading-none" :clearable="true" clear-icon clear-trigger v-model="fontText" placeholder="点击输入你的内容" />
        <div class="top-2 mr-1 w-7 h-7 rounded-full border border-gray-400 flex items-center justify-center leading-none z-50" @click="handleShowPopup">
          <i class="iconfont icon-confirm text-xs"></i>
        </div>
      </div>
      <van-tabs class="w-full h-full" shrink v-model:active="activeName" swipeable @click-tab="handleTab">
        <van-tab name="font" class="w-23">
          <template #title>
            <svg class="icon w-5 h-3 inline-block" aria-hidden="true">
              <use xlink:href="#icon-zitiicon"></use>
            </svg>
            字体
          </template>
          <div class="flex flex-col w-full h-full items-center">
            <better-scroll :isPullUpLoad="false" style="height: 245px">
              <elements-info seriesType="font" :itemObj="albumList"></elements-info>
            </better-scroll>
          </div>
        </van-tab>

        <van-tab name="color" class="color-menu h-full">
          <template #title>
            <svg class="icon w-5 h-3 inline-block" aria-hidden="true">
              <use xlink:href="#icon-colorpad-color"></use>
            </svg>
            颜色
          </template>
          <div class="flex flex-col w-full h-full items-center">
            <better-scroll :isPullUpLoad="false" style="height: 245px">
              <div class="flex w-full h-full pt-4 py-6 overflow-auto px-6">
                <div>
                  <div class="grid grid-cols-6 w-full pb-6 place-items-center gap-6">
                    <div
                      class="rounded-full bg-gray-200 flex items-center justify-center"
                      :style="
                        color == $store.state.activeColor
                          ? `background: #3f3f3f;padding: 2px; width: 2.375rem; height:2.375rem;`
                          : `background: linear-gradient(270deg, #E3E5E5 0%, #F7F7F7 99.94%, rgba(215, 215, 215, 0.5) 99.96%, #F5F5F5 99.97%, rgba(245, 245, 245, 0.3) 100%); padding: 2px; width: 2.375rem; height:2.375rem;`
                      "
                      v-for="({ color, id }, index) in getColorLibrary"
                      :key="id"
                      @click="handleColor(index)"
                    >
                      <div
                        class="w-full h-full rounded-full"
                        :style="{
                          background: `${color}`,
                          backgroundSize: 'cover',
                        }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </better-scroll>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { search } from "@/api/app.js";
import elementsInfo from "@/components/content/elementsInfo.vue";
import betterScroll from "@/components/common/betterScroll";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "FontView",
  components: {
    betterScroll,
    elementsInfo,
  },
  data() {
    return {
      fontText: "",
      activeName: "font",
      albumList: [], //每个type的全部数据
      mainType: 0, //type的状态
      currentPath: "", //当前的路由
      typeStatus: 2, //s数据类型
      active: 0, //当前的系列index
    };
  },
  watch: {
    fontText(newVal, oldVal) {
      newVal = newVal.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "");
      this.$bus.$emit(this.$store.state.currentPerspective + ":inputText", newVal);
    },
  },
  created() {
    this.currentPath = this.$route.path;
    this.CHANGE_MENU_STATUS(true);
    //获取数据
    setTimeout(() => {
      if (!this.albumList.length) {
        this.getSearch();
        console.log("this.albumList", this.albumList);
      }
    }, 100);
  },
  mounted() {},

  computed: {
    ...mapState(["menuStatus", "stickerId", "addSvgStatus", "isIndex", "activeColorKey", "is_knitting"]),
    ...mapGetters(["getColorLibrary"]),
  },

  methods: {
    ...mapMutations(["CHANGE_ADD_SVG", "CHANGE_MENU_STATUS", "CHANGE_INTERCEPT_STATUS", "CHANGE_SEARCH_STATUS", "SET_ACTIVE_COLOR"]),
    handleShowPopup() {
      this.CHANGE_MENU_STATUS(false);
    },
    handleTab() {
      console.log(this.activeName);
      // this.$emits("setTab", this.activeName.value);
    },
    handleColor(index) {
      this.$store.commit("SET_ACTIVE_COLOR", this.getColorLibrary[index].color);
      // if (!this.selectedColorKey.value && this.is_knitting.value == "1") return;
      this.$bus.$emit("changeColorConfig", {
        color: this.$store.state.is_knitting == "1" ? this.$store.state.activeColorKey : "primaryColor",
        value: this.getColorLibrary[index],
      });
    },
    handlerClick() {
      this.CHANGE_MENU_STATUS(false);
      setTimeout(() => {
        this.$router.replace({
          path: "/sticker",
        });
      }, 300);
    },
    // 搜索
    handlerSearch(value) {
      window._hmt.push(["_trackEvent", "handleSearch", "字体点击搜索"]);

      this.CHANGE_SEARCH_STATUS(value);
    },
    //获取列表数据,并做筛选出用户选中的字体
    getSearch() {
      search({
        type: 5,
        elementType: this.typeStatus,
      }).then(res => {
        this.albumList = res.data.data.list[0];
        // 重新计算高度
        // if (this.currentPath == "/sticker/font" && this.stickerId) {
        //   var filtered;
        //   this.albumList.forEach(item => {
        //     if (filtered) return;
        //     filtered = item.elements.find(i => {
        //       return i.id == this.stickerId;
        //     });
        //   });

        //   try {
        //     if (!this.addSvgStatus) return;
        //     // 可能会导致错误的代码
        //     let url = filtered.value;
        //     let options = {
        //       fontFamily: filtered.font_family,
        //       elementId: filtered.id,
        //       type: filtered.type,
        //     };

        //     setTimeout(() => {
        //       this.$bus.$emit(this.$store.state.currentPerspective + ":addFont", { url, options });
        //       this.CHANGE_ADD_SVG();
        //     }, 200);
        //   } catch (error) {
        //     console.log("切回路由不调用addSvg");
        //   }
        // }
        return;
      });
    },
    //获取收藏数据
    getCollect() {
      search({
        type: 7,
        mode_type: 2,
        elementType: 2,
      }).then(res => {
        this.collectList = res.data.data.list;
      });
    },

    onAlbumNum() {
      if (this.albumList.length > this.currentAlbumNum + 1) {
        this.currentAlbumNum += 1;
      }
    },
  },
};
</script>

<style lang="scss">
// .tab_container .van-tabs__nav--line.van-tabs__nav--complete {
//   padding-left: 0 !important;
//   padding-right: 0 !important;
// }
// .tab_container .van-tabs__wrap {
//   margin-left: 50px !important;
//   box-sizing: border-box;
//   height: 49px !important;
// }
// .tab_container .van-tabs__line {
//   display: none;

//   bottom: 20px !important;
// }
// .tab_container .van-tab--grow {
//   padding: 0 6px !important;
// }
// .content {
//   height: 268px;
// }
// .change-icon {
//   width: 12px;
//   height: 6px;
//   .arrow_icon {
//     width: 12px;
//     height: 6px;
//   }
// }
// .font-sty {
//   color: #ababab;
//   font-size: 10px;
//   line-height: 18px;
//   padding-left: 2px;
//   display: inline-block;
//   width: 46px;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

// .list-item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 62px;
//   height: 62px;
//   overflow: hidden;
//   background-color: #f5f5f5;
// }
// .scroll-container {
//   background-color: #f5f5f5;
//   // height: 268px;
//   width: 62px;
// }
// .img_box {
//   background-color: #f5f5f5;
//   border-radius: 6px;
//   overflow: hidden;
// }
// .items_img {
//   background-color: #e5e7eb;
// }
</style>
