<template>
  <div class="container">
    <div class="card-bar">
      <div class="avatar-name">
        <!-- <image class="avatar" src="" mode=""></image> -->
        <span class="name">{{ fontList.cate_name }}</span>
      </div>
    </div>
    <div class="font-stock-box">
      <div v-for="item in fontList.elements" :key="item.id">
        <div class="stock-item">
          <img class="icon-img" @click="collect(item)" v-if="item.elementIsCollection" src="@/static/collected_icon.png" />
          <img class="icon-img" @click="collect(item)" v-else src="@/static/collect_icon.png" />
          <img @click="checkedItem(item)" class="stock-img" :src="item.font_img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SetCollections } from "@/api/app.js";
import { Toast } from "vant";

export default {
  props: {
    fontList: {
      type: Object,
      require: true,
    },
  },
  methods: {
    checkedItem(item) {
      console.log(item);

      // console.log("点击了这个icon");
      let url = item.value;
      let options = {
        fontFamily: item.font_family,
        elementId: item.id,
        ipId: item.ip_id || 0,
        type: 2,
      };
      this.$bus.$emit(this.$store.state.currentPerspective + ":addFont", { url, options });
    },
    collect(item) {
      SetCollections({
        element_id: item.id,
        status: item.elementIsCollection ? 0 : 1,
      }).then(res => {
        if (res.status == 200) {
          if (!item.elementIsCollection) {
            Toast("收藏成功");
          }
          item.elementIsCollection = item.elementIsCollection ? 0 : 1;
          // if(all){
          // 	this.showCart = false
          // } else {
          // 	item.ipIsFocus = 1
          // }
          // this.handleSelectActiveElement(this.activeElement)
        } else {
          // uni.showToast({
          // 	title: res.msg
          // })
        }
        // this.$bus.$emit('getSeriese')
        // console.log(this.fontList);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  .card-bar {
    margin-bottom: 12px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    .avatar-name {
      display: flex;
      align-items: center;
      .avatar {
        margin-right: 12px;
        width: 25px;
        height: 25rpx;
        border-radius: 50%;
        background-color: #f5f5f5;
      }
      .name {
        line-height: 25px;
        font-weight: 500;
        font-size: 14px;
        color: #3f3f3f;
      }
    }
  }
  .font-stock-box {
    display: grid;
    grid-auto-rows: 109px;
    grid-template-columns: repeat(3, 109px);
    grid-template-rows: repeat(atuo-fill, 109px);
    gap: 10px 10px;
    .stock-item {
      position: relative;
      background-color: #f5f5f5;
      border-radius: 6px;
      width: auto;
      .icon-img {
        z-index: 99;
        border-radius: 6px;
        width: 18px;
        height: 20px;
        top: -5px;
        right: -5px;
        position: absolute;
      }
      .stock-img {
        height: 109px;
        width: 109px;
        border-radius: 6px;
      }
    }
  }
}
</style>
