<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.matched[0]?.name" v-if="$route.meta.keepAlive"></component>
    </keep-alive>
    <component :is="Component" :key="$route.matched[0]?.name" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script>
import { wxConfig } from "./api/app";
import { mapActions, mapMutations } from "vuex";
import { getItem } from "@/utils/utils";
import VConsole from "vconsole";
import { Dialog, Toast } from "vant";
export default {
  name: "App",
  data() {
    return {
      hasQuery: false,
    };
  },
  async created() {
    await wxConfig();

    document.addEventListener("UniAppJSBridgeReady", function () {
      // eslint-disable-next-line no-undef
      uni.getEnv(function (res) {
        console.log("当前环境：" + JSON.stringify(res));
      });
      // eslint-disable-next-line no-undef
      uni.postMessage({
        data: {
          action: "postMessage",
        },
      });
      window.postMessage({
        data: {
          action: "postMessage",
        },
      });
    });
    !(process.env.VUE_APP_ENV === "production") && new VConsole();

    // if(!this.hasQuery) {
    //   this.initQuery();
    // }
  },
  watch: {
    $route: {
      async handler() {
        if (!this.hasQuery) {
          await this.initQuery();
          this.hasQuery = true;
        }
        if (this.$route.path == "/sticker" && !this.$store.state.otherMenu) {
          if (this.$store.state.menuStatus) {
            this.$store.commit("CHANGE_MENU_STATUS", false);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setQueryString: "SET_QUERY_STRING",
      CHANGE_MENU_STATUS: "CHANGE_MENU_STATUS",
      SET_AIGC_INFO: "SET_AIGC_INFO",
    }),
    ...mapActions(["fetchColorLibrary", "fetchCarrierConfig"]),
    async initQuery() {
      console.log("route!", this.$route);

      const { query = null } = this.$route;
      if (query) {
        let config;
        if (query.aigc_img) {
          config = {
            productId: query.design_product_id || null,
            carrier_id: query.carrier_id || getItem("carrier_id"),
            token: query.token || getItem("token") || getItem("carrier_id"),
            stickerId: query.sticker_id || undefined,
            avatarType: query.avatarType || undefined,
            unique: query.unique || getItem("unique"),
            templateId: query.template_id ? query.template_id : null,
            mcId: query.mc_id ? query.mc_id : getItem("mcId"),
            mcMenu: query.mc_menu ? query.mc_menu : getItem("mcMenu"),
            extAvatarId: query.ext_avatar_id,
            channel: query.channel || getItem("channel"),
            isChild: query.is_child || getItem("isChild"),
            seriesId: query.series_id || getItem("seriesId"),
            isIndex: query.is_index || getItem("isIndex"),
            actName: query.act_name || getItem("actName"),
            designProductId: query.design_product_id,
            addChannel: query.ad_ch ? query.ad_ch : null,
            addName: query.ad_na ? query.ad_na : null,
            currentCateId: query.cate_id,
            activityCateId: query.activity_cate_id || getItem("activityCateId") || null, // 活动专属，用于请求活动专属载体
            aigcImg: query.aigc_img ? query.aigc_img : null,
          };

          this.SET_AIGC_INFO({
            url: config.aigcImg,
            options: {
              value: null,
              value1: null,
              value2: null,
              value3: null,
              type: 6,// aigc, 默认当做是我的素材
            },
          });
          setTimeout(() => {
            this.CHANGE_MENU_STATUS(true);
            if (config.aigcImg) this.$router.replace("/sticker/matter?type=ai");
          }, 1000);
          console.log("aigcConfig------------->", config.aigcImg);
        } else {
          config = {
            productId: query.design_product_id || null,
            carrier_id: query.carrier_id || getItem("carrier_id"),
            token: query.token || getItem("token"),
            stickerId: query.sticker_id || undefined,
            avatarType: query.avatarType || undefined,
            unique: query.unique || getItem("unique"),
            templateId: query.template_id ? query.template_id : null,
            mcId: query.mc_id ? query.mc_id : null,
            mcMenu: query.mc_menu ? query.mc_menu : null,
            extAvatarId: query.ext_avatar_id,
            channel: query.channel || getItem("channel"),
            isChild: query.is_child || getItem("isChild"),
            seriesId: query.series_id,
            isIndex: query.is_index || getItem("isIndex"),
            actName: query.act_name || getItem("actName"),
            designProductId: query.design_product_id,
            addChannel: query.ad_ch ? query.ad_ch : null,
            addName: query.ad_na ? query.ad_na : null,
            currentCateId: query.cate_id,
            activityCateId: query.activity_cate_id || getItem("activityCateId") || null, // 活动专属，用于请求活动专属载体
            aigcImg: query.aigc_img ? query.aigc_img : null,
          };
        }

        // 开发环境下，如果没有token，就用默认的测试token
        if (!config.token && process.env.VUE_APP_ENV === "development") {
          config = Object.assign(
            config,
            {
              token: 'Bearer Long',
              carrier_id: 272,
              url: "/sticker",
              is_knitting: 0,
              unique: "cfc1aee9",
              is_web: 1,
              is_child: 0,
              act_name: "df",
            },
          )          
        };

        if (!query.activity_cate_id && config.activityCateId) {
          // note: 如果是从活动页进入，但是没有传activity_cate_id，那么就用之前的activity_cate_id
          // note：但是从其他地方进入，应该把活动相关参数清除掉
          config.activityCateId = null;
          localStorage.removeItem("activityCateId");
        }

        if (config.addChannel) {
          console.log("记录广告来源", config.addChannel, config.addName);
          window._hmt.push(["_trackEvent", `add_channel:${config.addChannel}`, `add_channel:${config.addChannel},add_name:${config.addName}`]);
        }

        this.setQueryString(config);
        Object.keys(config).forEach(key => {
          if (config[key] == undefined || config[key] == null) {
            if (localStorage.getItem(key)) {
              window.localStorage.removeItem(key);
            }

            delete config[key];
            return;
          }
          localStorage.setItem(key, config[key]);
        });
      }
      await this.fetchColorLibrary();
      await this.fetchCarrierConfig();
    },
  },
};
</script>
<style lang="scss">
html,
body {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    // font-size: 35px;
  }

  // font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
