<!--
 * @Description: 弹出层菜单，更换载体、切换官方自制/艺术家模式、文字、形状等
 * @version: 
-->
<template>
  <div class="flex flex-wrap w-5/6 mx-auto items-center">
    <div
      v-for="item in isArtist ? navList.ipType : navList.freedomType"
      :key="String(item.id)"
      @click="handleClick(item.name)"
      class="w-1/2 py-[0.8rem]"
      v-show="!hasActivityCateId || (hasActivityCateId && !isArtist && item.id !== 4 && item.id !== 10)"
    >
      <!-- hasActivityCateId && !isArtist && item.id !== 4 && item.id !== 6， 封神活动隐藏切换艺术家 -->
      <div class="w-full flex flex-wrap items-center justify-center overflow-hidden">
        <!-- 【ID1000372】【创作中心UI界面修改】工具栏展开后，文字需调整大小以及粗细，并且整体的工具栏虚往上移动，现在太靠下了 -->
        <!-- 【ID1000371】【创作中心UI界面修改】icon重新更换，包括内页的icon也需同步修改：模版页面，我的素材页面，画笔，一键抠图，文字，更改颜色 -->
        <img :src="item.icon" alt="" :class="{ 
          'w-[5.3rem]': item.name == 'showFavouriteMenu', 
          'h-[5rem]': item.name != 'showFavouriteMenu', 
        }" />
        <div class="w-full text-center text-[1rem] font-semibold pt-[0.3rem]" :class="{ '-mt-[0.3rem]': item.name == 'showFavouriteMenu' }">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 导航模块：两个类型数据，IP模式和自由模式
 *
 * */
import { mapMutations, mapState } from "vuex";
import { Dialog, Toast } from "vant";
import { getItem } from "@/utils/utils";
import { AIGC_URL } from "@/config/api";

export default {
  name: "NavigationPop",
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: AIGC_URL,
      navList: {
        ipType: [
          {
            title: "更改载体",
            id: 1,
            name: "switchCarrier",
            icon: require("@/static/icons/genggaizaiti.png"),
          },
          {
            title: "进入官方模式",
            id: 2,
            name: "switchMode",
            icon: require("@/static/icons/qiehuanweiziyoumoshi.png"),
          },
        ],
        freedomType: [
          {
            title: "更改载体",
            id: 3,
            name: "switchCarrier",
            icon: require("@/static/icons/genggaizaiti.png"),
          },
          {
            title: "进入艺术家模式",
            id: 4,
            name: "switchMode",
            icon: require("@/static/icons/qiehuanIPmoshi.png"),
          },
          {
            title: "文字",
            id: 5,
            name: "showFontMenu",
            icon: require("@/static/icons/ziti.png"),
          },
          // {
          //   title: "形状",
          //   id: 6,
          //   name: 'showShapeMenu',
          //   icon: require('@/static/icons/xingzhuang.png')
          // },
          {
            title: "AI图像工具",
            id: 6,
            name: "navigateToAIGC",
            icon: require("@/static/icons/aigc.png"),
          },
          {
            title: "模版",
            id: 7,
            name: "showTemplateMenu",
            icon: require("@/static/icons/moban.png"),
          },
          {
            title: "画笔",
            id: 8,
            name: "showBrushMenu",
            icon: require("@/static/icons/huabi.png"),
          },
          {
            title: "头像",
            id: 9,
            name: "showAvatarMenu",
            icon: require("@/static/icons/touxiang.png"),
          },
          {
            title: "我的素材",
            id: 10,
            name: "showFavouriteMenu",
            icon: require("@/static/icons/wodesucai.png"),
          },
        ],
      },
      titleView: "切换官方自制",
    };
  },
  watch: {
    $route: {
      handler: async function (val) {
        // console.log("watch打印当前的路由", val);
        if (val.path.indexOf("/artist") > -1) {
          this.titleView = "切换官方自制";
        } else {
          this.titleView = "切换艺术家系列";
        }
        this.fromAvatar = this.$route.query.fromAvatar;
      },
      immediate: true,
      // 深度观察监听
      // deep: true,
    },
  },
  computed: {
    ...mapState(["showNavPop", "currentPerspective", "mcId", "activeColorKey"]),
    hasActivityCateId() {
      return getItem("activityCateId") || false;
    },
  },
  mounted() {},
  unmounted() {
    // this.$bus.$off("callCamera");
  },
  methods: {
    ...mapMutations(["SET_SHOW_NAV_POP", "CARRIER_SWITCH_STATUS", "CHANGE_AVATAR_TYPE", "CHANGE_AVATAR_TYPE", "CHANGE_MENU_STATUS"]),
    // 点击导航按钮
    handleClick(name) {
      let that = this;
      // 关闭弹窗
      that.SET_SHOW_NAV_POP(false);

      // 拉出菜单
      switch (name) {
        case "switchCarrier":
          that.switchCarrier();
          break;
        case "switchMode":
          that.switchRoute();
          break;
        case "showFontMenu":
          this.$bus.$emit("showMenuPopup", {
            isText: true,
            colorKey: this.activeColorKey,
            tab: "input",
          });
          window._hmt.push(["_trackEvent", "useFont", `使用字体`]);
          break;
        case "showBrushMenu":
          window._hmt.push(["_trackEvent", "usePaintbrush", `使用画笔`]);
          that.$bus.$emit(that.$store.state.currentPerspective + ":" + "enterDrawingMode");
          break;
        case "showAvatarMenu":
          window._hmt.push(["_trackEvent", "createAvatar", `去创作头像`]);
          console.log("点击跳转创作头像");
          this.CHANGE_AVATAR_TYPE(1);
          this.$router.push({
            path: "/avatar",
            query: {
              path_f: "menu",
            },
          });

          break;
        // case 'showFavouriteMenu':
        //   console.log('showFavouriteMenu');
        //   break;
        default:
          if (name !== "navigateToAIGC") {
            this.CHANGE_MENU_STATUS(true);
          }
          // this.lineCurrentIndex = 7;
          this.changeRouter(name);
      }
    },
    //切换载体
    switchCarrier() {
      if (this.mcId) {
        Dialog.confirm({
          title: "注意!",
          message: `更改载体后将丢失所有设计，是否继续？`,
          confirmButtonColor: "#23d59c",
        })
          .then(() => {
            window._hmt.push(["_trackEvent", "carrierButton", "switchCarrier"]);
            this.CARRIER_SWITCH_STATUS();
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.CARRIER_SWITCH_STATUS();
      }
    },
    // 路由切换
    switchRoute() {
      let currentRoute = this.$route.path;
      Dialog.confirm({
        title: "注意!",
        message: `切换至${this.titleView},会清空当前设计。`,
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          //【ID1000216】4.5 创作中心-用户-切换模式
          if (currentRoute.indexOf("/sticker") > -1) {
            this.$router.replace({
              path: "/artist/artist1",
            });
          } else if (currentRoute.indexOf("/artist") > -1) {
            this.$router.replace({
              path: "/sticker",
            });
          }
          window._hmt.push(["_trackEvent", "seriesSwitch", "switchSeries"]);
          let timeOut = setTimeout(() => {
            clearTimeout(timeOut);
            window.location.reload();
          }, 300);

          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    //监听菜单的点击,跳转对应的路由
    changeRouter(name) {
      let currentRoute = this.$route.path;
      switch (name) {
        case "showShapeMenu":
          if (currentRoute === "/sticker/shape") return;
          this.$router.replace({
            path: "/sticker/shape",
          });
          window._hmt.push(["_trackEvent", "changeRoute", "/sticker/shape"]);

          break;
        case "navigateToAIGC":
          window.location.assign(`${this.url}?from=editor&token=${window.localStorage.getItem("token")}`);
          break;
        case "showTemplateMenu":
          if (currentRoute === "/sticker/template") return;
          this.$router.replace({
            path: "/sticker/template",
          });
          window._hmt.push(["_trackEvent", "changeRoute", "/sticker/template"]);
          break;
        case "showFavouriteMenu":
          if (currentRoute === "/sticker/matter") return;
          this.$router.replace({
            path: "/sticker/matter",
          });
          window._hmt.push(["_trackEvent", "changeRoute", "/sticker/matter"]);
      }
    },
  },
};
</script>

<style></style>
