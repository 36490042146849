/*
 * @Date: 2023-08-22 14:14:15
 * @version:
 * @LastEditTime: 2023-10-26 12:05:29
 * @Description:
 */
const DEVELOP_URL = `https://alpha.tootools.cn/api`;
const PRODUCT_URL = `https://mp.tootools.cn/api`;
const DEVELOP_NODE_URL = `https://alpha.tootools.cn/node`;
const PRODUCT_NODE_URL = `https://mp.tootools.cn/node`;

export const HTTP_REQUEST_URL_AI = "https://uat-tooverse.tootools.cn:3999";

export const HTTP_REQUEST_URL = process.env.VUE_APP_ENV === "production" ? PRODUCT_URL : DEVELOP_URL;
export const HTTP_REQUEST_URL_NODE = process.env.VUE_APP_ENV === "production" ? PRODUCT_NODE_URL : DEVELOP_NODE_URL;

export const AIGC_URL =
  process.env.VUE_APP_ENV === "production" ? "https://aigc.tootools.cn/" : process.env.VUE_APP_ENV === "development" ? "https://alpha-aigc.tootools.cn/" : "http://local-editor.tootools.cn:8081";
console.log("-------------------当前aigc跳转路径----------------", AIGC_URL);

export const api = {
  get_element: `/element/index`,
  get_series: `/series/index`,
  search: `/shopSearch`,
  get_productCarrierDetailList: "/product/carrierDetailList",
  post_productSaveDesignProduct: "/product/saveDesignProduct",
  get_productDetail: "/product/detail/",
  get_shopElementsList: "/element/getShopElementsList",
  get_shopSearchList: "/getShopSearchList",
  post_uploadImage: "/upload/image",
  getClearSearch: "/v2/user/clean_search",
  post_setCollection: "/series/setCollections",
  postSetCollections: "/element/setCollections",
  get_elementPriceInfo: "/element/getElementPriceInfo",
  get_getTemplateInfo: "/product/getSemiCustomTempInfo/",
  get_MCCustomInfo: "/mass/getCustomInfo/",
  get_AIFace: "/toopeeks/getAIFace",
  get_carrierConfig: "/product/getCarrierConfigInfo",
  get_AVATAR_DETAIL: "/toopeeks/detail",
  post_UPLOAD_AVATAR: "/upload",
  get_USER_INFO_AVATAR: "/user/getOwnerInfo",
  get_CATEGORY_LIST: "/getCategory",
  get_CATEGORY_PRODUCT_LIST: "/getCategoryProductList",
  get_PRODUCT_DETAIL: "/product/carrierDetailList",
  get_OSS_TOKEN: "/getOssToken",
  get_SERIES_LIST: "/series/lst",
  get_SERIES_DETAIL: "/series/detail",
  get_IP_LIST: "/user/getShopIpList",
  post_USER_DO_FOCUS: "/user/doFocus",
  get_IP_INFO: "/user/getIpInfo",
  post_EXPORT_CANVAS: "/exportCanvas",
  get_USER_ELEMENTS: "/user/getUserElements",
  post_DEL_AVATAR: "/toopeeks/avatarDel",
  post_DEL_USER_ELE: "/user/deleteUserElements",
  post_SAVE_USER_ELEMENTS: "/user/saveUserElements",
  get_CART_PRODUCT_DESIGN_DETAIL: "/product/getCartProductDesignDetail",
  get_DESIGN_TEMP_LIST: "/product/getDesignTempList",
  get_DESIGN_JSON: "/product/getDesignTempJson",
  post_BIND_SHARE_DESIGN_POSTER: "/product/bindShareDesignPoster",
  get_USER_AI_ELEMENTS: "/user/getUserAiElements",
  post_USER_Ai_ELEMENTS: "/user/deleteUserAiElements",

  // 图片变高清相关功能
  post_AI_HD_IMAGE: "/aigc/txt2img",// aigc 图片变高清接口，使用aigc的文生图接口，参数固定
  get_AI_HD_IMAGE_STATUS: "/aigc/get_status", // 获取aigc，任务的状态
  get_AI_HD_IMAGE_RESULT: "/aigc/get_result", // 获取aigc，任务的结果
  delete_AI_HD_IMAGE_TASK: "/aigc/destroy", // 删除aigc高清图，任务
};

export const apiAi = {
  post_predict: "/tf-model/predict",
};
