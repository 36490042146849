<template>
  <div class="container">
    <div class="content flex justify-between">
      <div class="name">{{ seriesItem.ipInfo.real_name }}一{{ seriesItem.title }}</div>
      <div class="price-box">
        <div v-if="seriesItem.price && !judgePrice(seriesItem.price) == 0" class="price_icon">
          <span class="price-text">￥</span>
          <span class="price_count">{{ seriesItem.price }}</span>
        </div>
      </div>
    </div>
    <div class="main_content flex">
      <div class="left-mian-img relative">
        <img class="main-img" :src="seriesItem.cover_img" alt="" />
        <div class="absolute set_weight">
          <img @click="collect(0, seriesItem)" v-if="seriesItem.seriesIsCollection" src="@/static/collected_icon.png" class="eries-icon-1" />
          <img @click="collect(1, seriesItem)" v-else src="@/static/collect_icon.png" class="eries-icon-1" />
        </div>
      </div>
      <div class="right-content">
        <div
          :style="{
            backgroundColor: item.bg_color_value ? item.bg_color_value : '#f5f5f5',
          }"
          class="right-img"
          @click="checkedItem(item)"
          v-for="item in seriesItem.elementInfo"
          :key="item.id"
        >
          <tags-item :imgValue="item.value"></tags-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tagsItem from "@/components/content/tagsItem/tagsItem.vue";
import { Toast } from "vant";
import { setSeriesCollect } from "@/api/app.js";

export default {
  data() {
    return {};
  },
  props: {
    seriesItem: {
      type: Object,
      require: true,
    },
  },
  components: {
    tagsItem,
  },
  methods: {
    judgePrice(price) {
      return parseFloat(price);
    },
    checkedItem(item) {
      let url = item.value;
      let options = {
        value1: item.value1,
        value2: item.value2,
        value3: item.value3,
        ipId: this.seriesItem.ip_id,
        elementId: item.id,
        type: 5,
      };
      console.log(options);
      console.log(this.seriesItem.ip_id);
      this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", { url, options });
    },
    collect(status, item) {
      let that = this;

      setSeriesCollect({
        series_id: item.id,
        status,
      }).then(res => {
        if (res.status == 200) {
          if (status == 1) {
            Toast("收藏成功");
          }
          item.seriesIsCollection = item.seriesIsCollection ? 0 : 1;
        } else {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  .content {
    width: 100%;
    padding: 0 16px;
    display: flex;
    height: auto;
    justify-content: space-between;
    .eries-icon-1 {
      width: 18px;
      height: 20px;
    }
    .price-box {
      margin-right: 12rpx;
      margin-left: auto;
      .price_icon {
        display: flex;
        border-radius: 12px;
        height: 20px;
        padding: 0 11px 0 9px;
        line-height: 20px;
        background-color: #fcebb8;
        border-radius: 12px;
        .price-text {
          font-size: 12px;
          color: #ffb400;
          line-height: 20px;
        }
        .price_count {
          font-size: 12px;
          color: #ffb400;
          line-height: 20px;
          // display: inline-block;
        }
      }
    }
  }
  .main_content {
    margin-top: 16px;
    margin-bottom: 24px;
    padding: 0 16px;
    display: flex;
    width: 100%;
    box-sizing: border-box;

    .left-mian-img {
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      width: 80px;
      height: 80px;
      border-radius: 6px;

      .main-img {
        width: 80px;
        height: 80px;
        border-radius: 6px;
        background-color: #f5f5f5;
      }
      .set_weight {
        top: -9px;
        right: -5px;
      }
    }

    .right-content {
      display: grid;
      grid-template-columns: repeat(3, 80px);
      grid-template-rows: repeat(atuo-fill, 80px);
      grid-auto-rows: 80px;
      gap: 8px 8px;

      .right-img {
        height: 80px;
        width: 80px;
        border-radius: 6px;
        background-color: #f5f5f5;
        width: auto;
        height: auto;
      }
    }
  }
}
</style>
