import cornerDuplicate from "../../assets/images/icons/icon_corner_duplicate.png";
import cornerRemove from "../../assets/images/icons/icon_corner_remove.png";
import cornerRotate from "../../assets/images/icons/icon_corner_rotate.png";
import cornerScale from "../../assets/images/icons/icon_corner_scale.png";
import cornerEdit from "../../assets/images/icons/icon_corner_edit.png";

const iconCornerDuplicate = new Image();
const iconCornerRemove = new Image();
const iconCornerRotate = new Image();
const iconCornerScale = new Image();
const iconCornerEdit = new Image();

iconCornerDuplicate.src = cornerDuplicate;
iconCornerRemove.src = cornerRemove;
iconCornerRotate.src = cornerRotate;
iconCornerScale.src = cornerScale;
iconCornerEdit.src = cornerEdit;

export {
  iconCornerDuplicate,
  iconCornerRemove,
  iconCornerRotate,
  iconCornerScale,
  iconCornerEdit
};
