<!--
 * @Description: 顶部按钮栏位，上一步、下一步按钮
 * @version: 
-->
<template>
  <div class="absolute w-full h-full pointer-events-none">
    <!-- 【ID1000375】【创作中心UI界面】顶部button有点靠下，距离顶部bar为12 -->
    <!-- 左上角上一步、下一步 -->
    <div v-if="!isArtist" class="top-left absolute left-3 top-3 flex pointer-events-auto">
      <div @click="handleClick('undo')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center mr-[0.75rem] ">
        <i class="iconfont icon-undo text-sm"></i>
      </div>
      <div @click="handleClick('redo')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center mx-0">
        <i class="iconfont icon-redo text-sm"></i>
      </div>
    </div>

    <!-- 【ID1000373】【创作中心UI界面修改】左侧工具栏内的icon需放大，目前有点偏小 -->
    <!-- 右下角按钮区域 -->
    <div
      v-if="mcConfig.is_mass_customization != 0 || mcMenu == 1"
      class="absolute bottom-[0.75rem] right-[0.75rem] flex flex-col space-y-2 duration-150 origin-bottom pointer-events-auto"
      :class="{ 'scale-0 overflow-hidden': !toolsShow }"
    >
      <!-- 官方IP图标 -->
      <template v-if="!isArtist">
        <!-- 原保存至素材图标 -->
        <!-- <div  @click="saveSnapshot()" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
          <img class="object-contain w-[1.125rem]" src="@/static/folder.png" alt="" />
        </div> -->
        <!-- 新保存素材图标 -->
        <div v-if="!hasActivityCateId" @click="saveSnapshot()" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-save-to-folder text-[1.35rem]"></i>
        </div>
        <div @click="handleClick('bringForward')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-forward text-[1.375rem]"></i>
        </div>
        <div @click="handleClick('sendBackwards')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-backward text-[1.375rem] ml-[1px] mt-[1px]"></i>
        </div>
      </template>
      
      <!-- <div v-if="!isArtist" @click="handleClick('addUploadedImage')"
        class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-camera text-sm"></i>
      </div> -->

      <!-- artist page: 设置图标, 小小艺术家设置图标 -->
      <div v-else @click="onShowSettingPop('setting')" class="flex justify-center items-center w-9 h-9 bg-white rounded-full text-sm">
        <img src="@/static/icons/shezhi.png" alt="" class="w-[1.6rem]" />
      </div>

      <div @click="handleClick('setZoomToggle')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont text-[1.125rem]" :class="zoomToggle ? ' icon-zoomOut' : ' icon-zoomIn'"></i>
      </div>
    </div>

    <div v-else class="absolute bottom-1/3 -translate-y-1/2 right-3 flex flex-col space-y-2">
      <div @click="handleClick('setZoomToggle')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont text-[1.6rem] duration-150" :class="zoomToggle ? ' icon-zoomOut' : ' icon-zoomIn'"></i>
      </div>
    </div>

    <!-- <div class="absolute bottom-3 right-3 rounded-full w-10 h-10 flex justify-center items-center bg-white" @click="toggleTools">
      <img v-if="toolsShow" src="../static/tool.png" alt="" />
      <img v-else src="../static/tool_closed.png" alt="" />
    </div> -->

    <!-- 【ID1000273】4.15.4 创作中心-用户-前后左右面定制 工具栏-->
    <!-- 左下角更换正面、反面、左面、右面等 -->
    <div
      class="perspective-panel absolute bottom-[0.75rem] h-[1.875rem] left-[0.75rem] rounded-[0.9375rem] pointer-events-auto duration-150"
      :class="isShowPerspective ? 'rounded-t-none rounded-b-[0.9375rem]' : ''"
      v-if="perspectives.length > 1" ref="perspectivePopup"> 
      <div
        class="selected-item flex flex-nowrap justify-center items-center px-[0.75rem] py-[0.31rem] min-w-[4.375rem] h-[1.875rem] bg-white rounded-[0.9375rem] text-center"
        :class="isShowPerspective ? 'rounded-t-none rounded-b-[0.9375rem]' : ''"
        @click="onShowPerspective" >
        <div class="text-sm">{{ selectedPerspectiveName }}</div>
        <img src="@/static/arrow_top.png" alt="" class="h-[0.45rem] ml-[0.3rem] duration-150" :class="{ 'rotate-180': isShowPerspective }" />
      </div>
      <!-- 弹出层, 正面/反面等 -->
      <div
        v-show="isShowPerspective"
        class="absolute left-0 bottom-[1.87rem] transition duration-150 origin-right pt-[0.5rem] rounded-t-[0.9375rem] bg-white overflow-hidden"
        :class="{ 'scale-0 overflow-hidden': !toolsShow }">
        <div
          v-for="(item, index) in perspectives"
          :key="index"
          @click="handlePerspective(item.perspective, index)"
          class="relative min-w-[4.375rem] min-h-[1.875rem] flex justify-center items-center text-sm duration-150"
          :class="{ 'bg-[#F5F5F5]': selectedPerspective === item.perspective }">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { ref, getCurrentInstance } from 'vue';
import { useClickAway } from '@vant/use';
import { Toast } from "vant";
import { getItem } from "@/utils/utils";

export default {
  name: "ToolBar",
  setup() {
    const perspectivePopup = ref();
    let componentData = getCurrentInstance();
    useClickAway(
      perspectivePopup,
      () => {
        componentData.data.isShowPerspective = false;
      },
      { eventName: 'touchstart' }
    );
    return { perspectivePopup };
  },
  data() {
    return {
      isArtist: false, //是否为艺术家
      selectedPerspective: "front", //选中的面
      selectedPerspectiveName: "正面", //选中的面
      toolsShow: true, //工具栏是否弹出
      isShowPerspective: false,
    };
  },
  computed: {
    ...mapState(["carrierConfig", "zoomToggle", "mcConfig", "mcMenu", "showNavPop"]),
    perspectives() {
      const perspectives = [
        { perspective: "front", name: "正面" },
        { perspective: "back", name: "反面" },
        { perspective: "left", name: "左边" },
        { perspective: "right", name: "右边" },
      ];

      const res = [];

      perspectives.forEach(item => {
        //载体有几个面
        if (!this.carrierConfig[item.perspective]) {
          return;
        }
        res.push(item);
      });
      return res;
    },
    hasActivityCateId() {
      return getItem("activityCateId") || false;
    },
  },
  mounted() {
    // this.$bus.$on("callCamera", () => {
    //   this.handleClick("addUploadedImage");
    // });
    if (this.$route.matched.length > 1) {
      this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1;
    } else {
      this.isArtist = false;
    }
  },
  unmounted() {
    // this.$bus.$off("callCamera");
  },
  methods: {
    ...mapMutations([
      "SET_CURRENT_PERSPECTIVE", 
      "SET_ZOOM_TOGGLE", 
      "CHANGE_MENU_STATUS", 
      "SET_UPLOADED_IMAGE", 
      "SET_SHOW_NAV_POP",

      "CHANGE_INTERCEPT_IMG",
      "CHANGE_INTERCEPT_STATUS",
      "CHANGE_MENU_STATUS"
    ]),
    //【ID1000258】创作中心微调
    toggleTools() {
      this.toolsShow = !this.toolsShow;
    },
    onShowPerspective() {
      this.isShowPerspective = !this.isShowPerspective;
    },
    saveSnapshot() {
      this.$bus.$emit(this.$store.state.currentPerspective + ":saveSnapshot");
    },
    onShowSettingPop() {
      this.SET_SHOW_NAV_POP(true);
    },
    handleClick(event) {
      if (event === "setZoomToggle") this.SET_ZOOM_TOGGLE(!this.zoomToggle);
      this.$bus.$emit(this.$store.state.currentPerspective + ":" + event);
    },

    //【ID1000273】4.15.4 创作中心-用户-前后左右面定制 工具栏
    handlePerspective(event, index) {

      if (this.selectedPerspective === event) return Toast("当前是" + this.perspectives[index].name);

      this.$bus.$emit("hideMenuPopup"); // 隐藏所有弹窗

      // 当切换面时，隐藏图片操作菜单
      this.CHANGE_INTERCEPT_IMG("");
      this.CHANGE_INTERCEPT_STATUS("hide");
      this.CHANGE_MENU_STATUS(false);

      this.selectedPerspective = event;
      this.selectedPerspectiveName = this.perspectives[index].name;
      this.isShowPerspective = false;
      this.SET_CURRENT_PERSPECTIVE(event); // 设置当前的面
      this.$bus.$emit(event);
      this.$emit("switchPerspective", index); //切换到当前面的索引
      if (this.carrierConfig?.left && this.carrierConfig?.right) {
        //在正面背面渲染左袖右袖
        this.$bus.$emit("left:exportPerspectiveCanvas");
        this.$bus.$emit("right:exportPerspectiveCanvas");
      }

      this.$bus.$emit("front:renderPerspectiveCanvas");
      this.$bus.$emit("back:renderPerspectiveCanvas");
      // 放大每个面
      this.$bus.$emit("front:zoom150x");
      this.$bus.$emit("back:zoom150x");
      this.$bus.$emit("left:zoom150x");
      this.$bus.$emit("right:zoom150x");
    },
  },
};
</script>

<style></style>
