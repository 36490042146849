<template>
  <div class="relative">
    <!-- 展示的内容 -->
    <div ref="posterTargetActivity" :style="{ width: `${width}px`, height: `${height}px` }" class="relative w-full h-full poster-container">
      <div ref="posterCanvasActivity" class="fabric-canvas w-full absolute top-1/2 -translate-y-1/2">
        <canvas id="Canvas"></canvas>
      </div>
    </div>
    <!-- 弹出的海报 -->
    <!-- <vanPopup class="vanPopup_class" :style="{ width: `${width}px`, height: `${height}px` }" :custom-style="{ background: '#272727' }" v-model:show="isShowPopup">
      <van-loading class="z-40 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" vertical v-show="isShowLoading" color="#3F3F3F">加载中...</van-loading>
      <div class="w-full absolute top-1/3 -translate-y-1/2 z-10">
        <img class="w-full" :src="previewImg" alt="" />
      </div>
      <div class="absolute z-[9999] w-full items-center text-xs flex justify-center bottom-[-40px]">
        <i class="iconfont icon-download text-sm mr-1" style="color: #ffffff"></i> <span class="text-whiteMain">长按图片，保存至相册</span>
      </div>
    </vanPopup> -->
  </div>
</template>

<script>
// import posterBg from "@/static/share_poster.png";
import { uploadOSS } from "@/api/app";
import { dataURLtoBlob } from "@/utils/utils";
import { mapState } from "vuex";
// import { Popup } from "vant";
// import { fabric } from "fabric";
import { fabric } from "fabric-with-gestures-v5";

import { bindShareDesignPoster } from "@/api/app";

// const ratio = 651 / 383;
const ratio = 3045 / 1866;

export default {
  data() {
    return {
      isShowLoading: true,
      width: 300,
      height: 300 * ratio,
      ratio,
      scale: 300 / 383,
      previewImg: null,
    };
  },
  // components: { vanPopup: Popup },
  props: {
    QRcodeData: {
      type: Object,
      default(){
        return {}
      }
    },
    // showPopupStatus: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    ...mapState(["outputPreviewImages"]),
    // isShowPopup: {
    //   get() {
    //     return this.showPopupStatus;
    //   },
    //   set(value) {
    //     this.$emit("changeShow", value);
    //   },
    // }
  },
  methods: {
    
    async uploadImage(dataUrl) {
      const fileBlob = dataURLtoBlob(dataUrl);
      const res = await uploadOSS(fileBlob);
      console.log(res, res.options.endpoint + "/" + res.result.name);
      return res.options.endpoint + "/" + res.result.name;
    },

    async initCanvas(QRcodeData) {

      // 获取海报背景图的宽高比, 根据背景图的宽高比，计算海报的宽高比。 之前是直接按页面配置默认值生成。。 
      let that = this;
      let image = new fabric.Image.fromURL(QRcodeData.poster_bg_img, function(img) {
        // 图像加载完成后的回调函数
        let ratio = img.height / img.width;  // 计算宽高比
        if (ratio != that.ratio) {
          that.ratio = ratio;
          that.height = that.width * ratio;
        };
      });

      console.log("生成封神海报", QRcodeData, QRcodeData.avatar);

      this.canvas = new fabric.Canvas("posterCanvasActivity");
      this.resizeCanvas();

      await this.addBg(QRcodeData);// 添加背景图
      if (!QRcodeData.name) await this.addShareInfo(QRcodeData);// 添加二维码信息
      await this.addFontDesign(QRcodeData);// 添加分享的设计图

      if (QRcodeData.name) await this.addAvatarAndQRcode(QRcodeData);// 添加头像和二维码
      if (QRcodeData.name) await this.addName(QRcodeData);// 添加用户昵称

      this.canvas.renderAll();
      let outputFrontDesignImg = await this.exportCanvas();

      // 导出海报后，直接绑定分享海报和商品
      this.bindPosterWithProduct(QRcodeData.product_id, outputFrontDesignImg);
      return outputFrontDesignImg;
    },

    /**
     * @name: 绑定海报至商品
     * @param {*} product_id 商品id
     * @param {*} share_poster 海报图片url
     * @return {*}
     */
    async bindPosterWithProduct(product_id, share_poster) {
      // 调接口绑定分享海报和商品
      await bindShareDesignPoster({ product_id, share_poster });
    },

    addImage(url, { left, top, width, height, originX, originY }, stroke = false) {
      return new Promise(resolve =>
        fabric.Image.fromURL(
          url,
          img => {
            img.set({
              left,
              top,
              rx: 10,
              ry: 10,
              originX: originX || "center",
              originY: originY || "center",
              scaleX: (width / img.width).toFixed(2),
              scaleY: (height / img.height).toFixed(2),
              backgroundColor: stroke ? "fff" : "transparent"
              // selectable: false,
            });
            if (stroke) {
              img.set({
                stroke: "#000", // 边框
                strokeWidth: 5, // 用于渲染此对象的笔划宽度
                cornerSize: 10,
                // backgroundColor: "#fff",
                rx: 100,
                ry: 100,
              });
            };
            this.canvas.add(img);
            resolve(img);
          },
          { crossOrigin: "Anonymous" },
        ),
      );
    },

    addRect({ left, top, width, height, originX, originY, fill }) {
      const rect = new fabric.Rect({
        left,
        top,
        width,
        height,
        rx: 10,
        ry: 10,
        originX: originX || "center",
        originY: originY || "center",
        fill,
        selectable: false,
        absolutePositioned: true,
        stroke: "#000", // 边框
        // padding: 10, // 对象及其控制边框之间的填充（以像素为单位）
        strokeWidth: 0.5, // 用于渲染此对象的笔划宽度
      });

      this.canvas.add(rect)
      // if (!fillImg) return;

      // let that = this;
      // fabric.Image.fromURL(fillImg, function(img) {


      //   img.scaleToWidth(rect.width);
      //   img.scaleToHeight(rect.height);

      //   rect.set('fill', new fabric.Pattern({
      //     source: function() {
      //       var patternCanvas = fabric.util.createCanvasElement();
      //       patternCanvas.width = img.width;
      //       patternCanvas.height = img.height;
      //       var patternCtx = patternCanvas.getContext('2d');
      //       patternCtx.drawImage(img.getElement(), 0, 0, img.width, img.height);
      //       return patternCanvas;
      //     }
      //   }));
      //   that.canvas.requestRenderAll();

      //   console.log("需要填充=========", rect);

      // });

      // that.canvas.add(rect);
    },

    addText({ left, top, text, fontSize, originX, originY }) {
      const textObj = new fabric.Text(text, {
        left,
        top,
        fontSize,
        originX: originX || "center",
        originY: originY || "center",
        fill: "#272727",
        selectable: false,
        absolutePositioned: true,
        fontFamily: "PingFangSC-Medium, PingFang SC",
        fontWeight: 500
      });
      this.canvas.add(textObj);
    },

    resizeCanvas() {
      console.log({ width: this.width });
      this.canvas.setDimensions({
        width: this.width,
        height: this.height,
      });
      this.canvas.renderAll();
    },
    
    async addBg(QRcodeData) {
      const left = this.canvas.getCenter().left;
      const top = this.canvas.getCenter().top;
      const width = this.width + 2;
      const height = this.height + 2;
      // const width = this.width;
      // const height = this.height;
      const image = await this.addImage(QRcodeData.poster_bg_img, { left, top, width, height });
      this.canvas.renderAll();
    },

    async addAvatarAndQRcode(QRcodeData) {

      // 渲染左侧头像
      const width = 76 * this.scale;
      const height = 76 * this.scale;
      const left = 10 * this.scale;
      const top = this.height - 80 * this.scale;

      // fixit: 修复头像的圆角和边框。 
      // note: 暂时不需要为头像添加圆角和边框  
      // let values = {
      //   left,
      //   top,
      //   width,
      //   height,
      //   originX: "left",
      //   originY: "bottom",
      //   fill: "#fff"
      // };
      // this.addRect(values);

      await this.addImage(QRcodeData.avatar, {
        // left: left + 4,
        // top: top - 3,
        // width: width - 6,
        // height: height - 6,
        top,
        left,
        width,
        height,       
        originX: "left",
        originY: "bottom"
      }, false);

      // 渲染右侧二维码背景
      const rect = new fabric.Rect({
        left: this.width - 10 * this.scale,
        top:  this.height - 160 * this.scale,
        width: 70 * this.scale,
        height: 70 * this.scale,
        rx: 10,
        ry: 10,
        originX: "right",
        originY: "bottom",
        fill: "#fff",
        selectable: false,
        absolutePositioned: true,
        stroke: "#000", // 边框
        // padding: 10, // 对象及其控制边框之间的填充（以像素为单位）
        strokeWidth: 0.5, // 用于渲染此对象的笔划宽度
      });
      this.canvas.add(rect);

      // 渲染右侧二维码
      await this.addImage(QRcodeData.qrcode, {
        left: this.width - 10 * this.scale - 4,
        top: this.height - 160 * this.scale - 4,
        width: 62 * this.scale,
        height: 62 * this.scale,
        originX: "right",
        originY: "bottom",
      });
    },

    // 渲染左侧默认二维码
    async addShareInfo(QRcodeData) {

      // let isFSActivity = QRcodeData.posterType && QRcodeData.posterType == 2;
      // let scale = isFSActivity ? 60 : 115;

      let width = 65 * this.scale;
      let height = 65 * this.scale;

      let left = 15 * this.scale;
      let top = this.height - 92 * this.scale;

      this.addQrCodeBound(QRcodeData);
      await this.addImage(QRcodeData.qrcode, {
        left,
        top,
        width,
        height,
        originX: "left",
        originY: "bottom"
      });

    },

    // 渲染二维码边框
    addQrCodeBound(QRcodeData) {

      // let isFSActivity = this.QRcodeData.posterType && this.QRcodeData.posterType == 2;
      // let scale = isFSActivity ? 60 : 115;

      const width = 76 * this.scale;
      const height = 76 * this.scale;
      const left = 10 * this.scale;
      const top = this.height - 87 * this.scale;

      let values = {
        left,
        top,
        width,
        height,
        originX: "left",
        originY: "bottom",
        fill: "white",
      };

      this.addRect(values);

    },

    async addName(QRcodeData) {
      const left = 10 * this.scale + 76 * this.scale + 10 * this.scale;
      const top = this.height - 118 * this.scale;

      this.addText({
        text: QRcodeData.name,
        left,
        top,
        fontSize: 23 * this.scale,
        originX: "left",
        originY: "bottom",
      })
    },

    async addFontDesign(QRcodeData) {
      const { left, top } = this.canvas.getCenter();

      // let isFSActivity = QRcodeData.posterType && QRcodeData.posterType == 2;
      // let scale = isFSActivity ? 330 : 400;

      const width = 330 * this.scale;
      const height = 330 * this.scale;

      const img = await this.addImage(this.outputPreviewImages.front, {
        left,
        // top: isFSActivity ? top + 10 : top,
        top: top  +  10,
        width,
        height,
        originX: "center",
        originY: "center",
        clipPath: true,
      });
    },

    exportCanvas() {
      return new Promise((resolve, reject) => {
        this.previewImg = this.canvas.toDataURL({
          multiplier: 900 / this.canvas.width,
          format: "png",
          crossOrigin: "anonymous",
          quality: 0.5,
        });
        
        let img = this.uploadImage(this.previewImg);
        this.isShowLoading = false;
        resolve(img);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.poster-container {
  background-color: #fff;
  height: 379px;
  width: 223px;
}
.vanPopup_class {
  .van-overlay {
    overflow-y: unset !important;
  }
}
</style>
