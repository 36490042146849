<template>
  <div>
    <div class="my-search-container">
      <div class="my-search-wrapper">
        <div class="search-box">
          <img class="search-icon" src="@/static/search.png" alt="" />
          <!-- <image class="search-icon" src="" mode=""></image> -->
          <input
            ref="inpurRef"
            placeholder-class="placeholder"
            maxlength="20"
            class="search-input"
            type="search"
            @keyup.enter="onConfirm"
            v-model="searchValue"
            @focus="isCancelShow = true"
            @blur="onBlur"
            @input="debouncedHandler"
            :placeholder="placeholderText"
          />
          <!-- @confirm="onConfirm" -->
          <div v-if="searchValue" class="fork-icon">
            <img @click="selectAll" class="icon" :src="forkIcon" />
          </div>
        </div>
        <text v-show="true" @click="doCancel" class="cancel">取消</text>
      </div>
    </div>
  </div>
</template>
<script>
// import { OSS_IMG_URL } from "@/config/app.js";
import a1 from "@/static/clear.png";
import a2 from "@/static/clear2.png";
import { mapMutations } from "vuex";
import debounce from "lodash.debounce";

export default {
  name: "my-search",
  data() {
    return {
      //   OSS_IMG_URL,
      isCancelShow: false,
      // placeholderText:'搜索感兴趣的创作',
      searchValue: "",
      forkStatus: false,
    };
  },
  created() {
    this.debouncedHandler = debounce((newValue, oldValue) => {
      if (this.searchValue == "") return;
      this.$emit("setSearchValue", this.searchValue);
    }, 800);
  },
  props: {
    searchitem: {
      type: String,
    },
    // searchInit: {
    //   type: String,
    // },
    placeholderText: {
      type: String,
      default() {
        return "搜索关键词";
      },
    },
  },
  watch: {
    searchValue: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.forkStatus = true;
        } else {
          this.forkStatus = false;
        }
      },
      immediate: true,
    },
    searchitem(newValue, oldValue) {
      if (newValue.length) {
        this.searchValue = newValue;
      }
    },
    // searchInit: {
    //   handler(newValue, oldValue) {
    //     this.searchValue = newValue;
    //   },
    // },
  },
  computed: {
    // fork状态
    forkIcon() {
      if (this.searchValue) {
        if (this.isCancelShow) {
          return a1;
        } else {
          return a1;
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      closeSearch: "CHANGE_SEARCH_STATUS",
    }),
    //输入触发
    onInput(e) {
      // this.searchValue = e.detail.value
    },
    // 点击取消
    doCancel() {
      this.closeSearch();
      this.isCancelShow = false;
      this.searchValue = "";
      this.$emit("handleCancel");
      this.$bus.$emit("changeCollectStatus");
    },
    //失去焦点的时候
    onBlur() {
      this.isCancelShow = false;
      console.log("我失去焦点");
    },
    //获取焦点
    doFocus() {
      console.log("获取焦点");
    },
    //点击提交的时候
    onConfirm() {
      this.$emit("setSearchValue", this.searchValue);
      this.$refs.inpurRef.blur();
    },
    // 删除搜索内容
    selectAll() {
      this.searchValue = "";
    },
  },
  beforeUnmount() {
    this.debouncedHandler.cancel();
  },
};
</script>
<style lang="scss" scoped>
input {
  outline: none;
}
input::-webkit-search-cancel-button {
  display: none;
}
.my-search-container {
  background-color: #fff;
  padding: 0 12px;
  width: 100%;
  box-sizing: border-box;
  .my-search-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search-box {
      border-radius: 25px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border: 1px solid #3f3f3f;
      height: 36px;
      width: 100%;
      .placeholder {
        color: #ababab;
        font-size: 14px;
        font-weight: 400;
      }
      .search-icon {
        margin-left: 12px;
        height: 16px;
        width: 16px;
      }
      .search-input {
        -webkit-appearance: none;
        background-color: #fff;
        border-radius: 0;
        margin-left: 12px;
        display: inline-block;
        height: 20px;
        width: 225px;
      }
      .fork-icon {
        margin-left: auto;
        margin-right: 12px;

        width: 15px;
        height: 15px;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
    }
    .cancel {
      height: 25px;
      line-height: 25px;
      white-space: nowrap;
      margin-left: 12px;
    }
  }
}
</style>
