// import goodStorage from "good-storage";
// export class ImgUtil {
//   static imgList = {};
//   static storageImageList() {
//     this.imgList = goodStorage.get("imgList") || {};
//     if (this.isEmpty()) {
//       ImgUtil.loadImg();
//       goodStorage.set("imgList", ImgUtil.imgList);
//     } else {
//       return;
//     }
//   }
//   static getImg(name) {
//     return ImgUtil.imgList[name];
//   }
//   static isEmpty() {
//     return !Object.getOwnPropertyNames(this.imgList).length;
//   }
//   static loadImg() {
//     let imgMap = require.context("../static", false, /\w(.png|.jpg|.svg)$/);
//     imgMap.keys().forEach(item => {
//       let key = item.substring(item.lastIndexOf("/") + 1);
//       ImgUtil.imgList[key] = "@/static" + item.replace(".", "");
//     });
//     console.log("imgMap", imgMap.keys());
//   }
// }
export function compressedImageFuc(file) {
  console.log("压缩前大小：" + (file.size / (1024 * 1024)).toFixed(2) + "MB");
  let reader = new FileReader();
  //这里把一个文件用base64编码
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onload = function (e) {
      let newImg = new Image();
      //获取编码后的值,也可以用this.result获取
      newImg.src = e.target.result;
      newImg.onload = function () {
        /* 获取  this.height tthis.width*/
        var dataURL = compress(newImg, this.width, this.height, 5); //15MB
        /*为了兼容ios 需要 dataURL-> blob -> file */
        var blob = dataURLtoBlob(dataURL); // dataURL-> blob
        var file = blobToFile(blob, "999"); // blob -> file
        // console.log("得到文件：" + file);
        console.log("压缩后大小：" + (file.size / (1024 * 1024)).toFixed(2) + "MB");
        resolve(file);

        // document.body.append("<img src='" + dataURL + "' />");
      };
    };

    reader.onerror = function (err) {
      reject(err);
    };
  });
}

function compress(img, width, height, maxFileSize) {
  // img可以是dataURL或者图片url
  /*	如果宽度大于 300 图片太大 等比压缩 	*/
  // let { h, w, s } = isScale(width, height, 4000);
  let maxWidth = 1024
  let { h, w, s } = isScale(width, height, maxWidth); // 优化图片尺寸
  var canvas, ctx, img64;
  canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;

  ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, w, h);
  /* canvas.toDataURL(mimeType（图片的类型）, qualityArgument（图片质量）) */
  ctx.fillStyle = "#fff";
  // img64 = canvas.toDataURL("image/jpeg", 0.7);
  img64 = canvas.toDataURL();
  if (maxFileSize <= computedSize(img64)) {
    compress(...arguments);
    console.log("base64计算后文件大小", computedSize(img64));
  } else {
    console.log("base64计算后文件大小", computedSize(img64));

    // console.log("img64", img64);
    return img64; // 压缩后的base64串
  }
}

function computedSize(dataUrl) {
  let str = dataUrl.replace("data:image/png;base64,", "");
  let equalIndex = str.indexOf("=");
  if (str.indexOf("=") > 0) {
    str = str.substring(0, equalIndex);
  }
  let strLength = str.length;
  let fileLength = parseInt(strLength - (strLength / 8) * 2) / (1024 * 1024);
  return fileLength.toFixed(2);
}
function isScale(width, height, maxPx) {
  let h, w, s;

  if (width > maxPx || height > maxPx) {
    if (width >= height) {
      s = maxPx / width;
      w = maxPx;
      h = s * height;
    } else {
      s = maxPx / height;
      h = maxPx;
      w = s * width;
    }
    return {
      h,
      w,
      s,
    };
  }
  return {
    h: height,
    w: width,
    s: 1,
  };
}

//将base64转换为blob
function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

//将blob转换为file
function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date().getTime();
  theBlob.name = fileName;
  return theBlob;
}

// export default ImgUtil.getImg;

export function getBase64(imgUrl) {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    // 至关重要
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status == 200) {
        //得到一个blob对象
        var blob = this.response;
        console.log("blob", blob)
        // 至关重要
        let oFileReader = new FileReader();
        oFileReader.onloadend = function (e) {
          // 此处拿到的已经是 base64的图片了
          let base64 = e.target.result;
          resolve(base64);
        };
        oFileReader.readAsDataURL(blob);
    }
    }
    xhr.send();
  })

}

// 获取图片宽高
export function getImageSize (imgUrl) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = imgUrl;
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height
      })
    }
  })
}

// 图片URL转file
export function urlToFile(url) {

  const [mimeType, format] = getMimeTypeFromHttpUrl(url);
  const filename = `hd_input.${format}`

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        blob.lastModifiedDate = new Date();
        blob.name = filename;
        resolve(new File([blob], filename, { type: mimeType }));
      } else {
        reject(new Error(`Failed to load ${url}: ${xhr.status} ${xhr.statusText}`));
      }
    };
    xhr.onerror = () => {
      reject(new Error(`Failed to load ${url}`));
    };
    xhr.send();
  });
}

// 获取图片格式
function getMimeTypeFromHttpUrl(url) {
  const match = url.match(/^.+\.([^.?]+)(\?.*)?(#.*)?$/);
  if (match) {
    const extension = match[1];
    const fileFormat = extension.toLowerCase();
    switch (fileFormat) {
      case "jpg":
      case "jpeg":
        return ["image/jpeg", fileFormat];
      case "png":
        return ["image/png", fileFormat];
      case "gif":
        return ["image/gif", fileFormat];
      case "webp":
        return ["image/webp", fileFormat];
      default:
        return "";
    }
  } else {
    return "";
  }
}
