/**
 * Should objects be aligned by a bounding box?
 * [Bug] Scaled objects sometimes can not be aligned by edges
 *
 */
// import "./event.js"
export function initAligningGuidelines(canvas) {
  // console.log("加载aligning_guidelines.js===================", canvas);
  var ctx = canvas.getSelectionContext(),
    aligningLineOffset = 5,
    aligningLineMargin = 4,
    aligningLineWidth = 1,
    aligningLineColor = "rgba(35,213,156,1)",
    viewportTransform,
    zoom = 1;

  function drawVerticalLine(coords) {
    drawLine(coords.x + 0.5, coords.y1 > coords.y2 ? coords.y2 : coords.y1, coords.x + 0.5, coords.y2 > coords.y1 ? coords.y2 : coords.y1);
  }

  function drawHorizontalLine(coords) {
    drawLine(coords.x1 > coords.x2 ? coords.x2 : coords.x1, coords.y + 0.5, coords.x2 > coords.x1 ? coords.x2 : coords.x1, coords.y + 0.5);
  }

  function drawLine(x1, y1, x2, y2) {
    var originXY = fabric.util.transformPoint(new fabric.Point(x1, y1), canvas.viewportTransform),
      dimensions = fabric.util.transformPoint(new fabric.Point(x2, y2), canvas.viewportTransform);
    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();

    ctx.moveTo(originXY.x, originXY.y);

    ctx.lineTo(dimensions.x, dimensions.y);
    ctx.stroke();
    ctx.restore();
  }

  function isInRange(value1, value2) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin; i <= len; i++) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }

  var verticalLines = [],
    horizontalLines = [];

  canvas.on("mouse:down", function (e) {
    viewportTransform = canvas.viewportTransform;
    zoom = canvas.getZoom();
    // console.log("mouse:down", e);

    // 手指按下的时候，允许移动。防止缩放后，无法移动图层。
    const activeObj = canvas.getActiveObject();
    if (activeObj) {
      // console.log("鼠标按下后的activeObj", activeObj);
      activeObj.set({
        lockMovementX: false,
        lockMovementY: false,
      });

      // 【ID1000369】【创作中心UI界面】形状无法进行伸缩
      // 【ID1000368】【创作中心UI界面】先选择一枚贴纸，后再选择一枚形状，形状则无法编辑颜色；若单独只有1枚形状，可以修改颜色
      let controlsPoint = [
        {
          typeIds: [1, 5, 6, 10, "1", "5", "6", "10"], // 头像、贴纸、素材、照片，有【删除】【复制】【放大】
          controls: {
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          },
        },
        {
          typeIds: [2, "2"], //文字， 有【删除】【复制】【放大】【编辑】
          controls: {
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: true,
          },
        },
        {
          typeIds: [4, "4"], // 形状，有【删除】【复制】【放大】【编辑】【四边中点的缩放】
          controls: {
            mt: true,
            mb: true,
            ml: true,
            mr: true,
            tr: false,
            editControl: true,
          },
        },
      ];

      let controlsVisibility = controlsPoint.find(i => i.typeIds.includes(activeObj.typeId));
      // console.log("当前点击的对象, 第一步find的结果", controlsVisibility);
      let controls = controlsVisibility?.controls || controlsPoint[0].controls;
      // console.log("当前点击的对象", controls, activeObj.typeId);
      activeObj.setControlsVisibility(controls);
    }
  });

  // done: 手势操作，用于放大和旋转。V4.5版本支持，5.x正在处理中。 已安装fabric-with-gestures-v5包进行处理该问题 。
  canvas.on("touch:gesture", function (e) {
    // 触发手势使用的时候，禁止移动。
    const activeObj = canvas.getActiveObject();
    if (activeObj) {
      // console.log("当前活动的对象", activeObj);
      activeObj.set({
        lockMovementX: true,
        lockMovementY: true,
      });
    }
    // console.log("手势", e);
  });

  //   canvas.on("longpress", function (e) {
  //     // 触发手势使用的时候，禁止移动。
  //     const activeObj = canvas.getActiveObject();
  //     if (activeObj) {
  //       // console.log("当前活动的对象", activeObj);
  //       activeObj.set({
  //         lockMovementX: true,
  //         lockMovementY: true,
  //       });
  //     }
  //     console.log("长按");
  //   });

  // fixit: 移动端当放大到一定程度的时候，图层会消失。
  // 监听缩放事件
  const maxZoom = 2; // 设置最大缩放级别
  canvas.on("object:scaling", function (event) {
    let { zoomX, zoomY, scaleX, scaleY } = event.target;
    const activeObj = canvas.getActiveObject();
    // if (activeObj) {
    //   activeObj.set({
    //     lockScalingX: zoomX > 0.1 ? true : false,
    //     lockScalingY: zoomY > 0.1 ? true : false,
    //   });
    // }
    // console.log("缩放监听", zoomX, zoomY, scaleX, scaleY);
  });

  // canvas.on('object:modified', (opt) => {
  //   // inspect action and check if the value is what you are looking for
  //   console.log("modified 事件", opt);
  //   const action = opt.action;
  // });

  canvas.on("object:moving", function (e) {
    // console.log("移动", e);
    var activeObject = e.target,
      canvasObjects = canvas.getObjects().filter(o => o["defaultId"] !== "carrierImage"),
      activeObjectCenter = activeObject.getCenterPoint(),
      activeObjectLeft = activeObjectCenter.x,
      activeObjectTop = activeObjectCenter.y,
      activeObjectBoundingRect = activeObject.getBoundingRect(),
      activeObjectHeight = activeObjectBoundingRect.height / viewportTransform[3],
      activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
      horizontalInTheRange = false,
      verticalInTheRange = false,
      transform = canvas._currentTransform;

    if (!transform) return;

    // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
    // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

    for (var i = canvasObjects.length; i--; ) {
      if (canvasObjects[i] === activeObject) continue;

      var objectCenter = canvasObjects[i].getCenterPoint(),
        objectLeft = objectCenter.x,
        objectTop = objectCenter.y,
        objectBoundingRect = canvasObjects[i].getBoundingRect(),
        objectHeight = objectBoundingRect.height / viewportTransform[3],
        objectWidth = objectBoundingRect.width / viewportTransform[0];

      // snap by the horizontal center line
      if (isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft,
          y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
          y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft, activeObjectTop), "center", "center");
      }

      // snap by the left edge
      if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft - objectWidth / 2,
          y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
          y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft - objectWidth / 2 + activeObjectWidth / 2, activeObjectTop), "center", "center");
      }

      // snap by the right edge
      if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft + objectWidth / 2,
          y1: objectTop < activeObjectTop ? objectTop - objectHeight / 2 - aligningLineOffset : objectTop + objectHeight / 2 + aligningLineOffset,
          y2: activeObjectTop > objectTop ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset,
        });
        activeObject.setPositionByOrigin(new fabric.Point(objectLeft + objectWidth / 2 - activeObjectWidth / 2, activeObjectTop), "center", "center");
      }

      // snap by the vertical center line
      if (isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop,
          x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop), "center", "center");
      }

      // snap by the top edge
      if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop - objectHeight / 2,
          x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop - objectHeight / 2 + activeObjectHeight / 2), "center", "center");
      }

      // snap by the bottom edge
      if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop + objectHeight / 2,
          x1: objectLeft < activeObjectLeft ? objectLeft - objectWidth / 2 - aligningLineOffset : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2: activeObjectLeft > objectLeft ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset,
        });
        activeObject.setPositionByOrigin(new fabric.Point(activeObjectLeft, objectTop + objectHeight / 2 - activeObjectHeight / 2), "center", "center");
      }
    }

    if (!horizontalInTheRange) {
      horizontalLines.length = 0;
    }

    if (!verticalInTheRange) {
      verticalLines.length = 0;
    }
  });

  canvas.on("before:render", function () {
    canvas["contextTop"] && canvas.clearContext(canvas.contextTop);
  });

  canvas.on("after:render", function () {
    for (var i = verticalLines.length; i--; ) {
      drawVerticalLine(verticalLines[i]);
    }
    for (var i = horizontalLines.length; i--; ) {
      drawHorizontalLine(horizontalLines[i]);
    }

    verticalLines.length = horizontalLines.length = 0;
  });

  canvas.on("mouse:up", function () {
    verticalLines.length = horizontalLines.length = 0;
    canvas.off("mouse:move");
    canvas.renderAll();
  });
}
