<template>
  <div style="width: 100%; height: 100%">
    <template v-if="isShape">
      <div class="container">
        <van-image @load="imgLoad" width="100%" height="100%" fit="contain" :src="getImgValue" />
      </div>
    </template>
    <div v-if="isPadding" class="container_ll">
      <template v-if="!isShape">
        <van-image width="100%" height="100%" fit="contain" :src="getImgValue" />
      </template>
    </div>
    <div v-if="!isPadding" class="container_le">
      <template v-if="!isShape">
        <van-image width="100%" height="100%" fit="contain" :src="getImgValue" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    imgLoad() {
      console.log("图片加载完成");
    },
  },
  props: {
    //当前的图片
    imgValue: String,
    isShape: {
      type: Boolean,
      default: false,
    },
    //是否有padding
    isPadding: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    //改变图片大小
    getImgValue() {
      return /.(jpg|png)/.test(this.imgValue) ? this.imgValue + "?x-oss-process=image/resize,w_300,h_300/quality,q_50" : this.imgValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.container_ll {
  width: 100%;
  height: 100%;
  padding: 8px;
}
.container_le {
  border-radius: 6px;
  width: 100%;
  height: 100%;
}
</style>
