/*
 * @Description: 
 * @version: 
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bus from "./utils/eventbus";
import { Toast, Swipe, SwipeItem, Dialog, Image as VanImage, Popup, Tabs, Tab, Slider, Loading, Uploader, Button, Field, Icon } from "vant";
import "vant/lib/index.css";
import "default-passive-events";
import "./assets/css/iconfont";
import "./index.css";
import { userAction } from "@/utils/userAction";
import { log, logs } from "@/utils/utils";

// import { ImgUtil } from "@/utils/imgUtils";
const app = createApp(App);
// ImgUtil.storageImageList();

app.config.globalProperties.$bus = bus;
app.config.globalProperties.$log = log;
app.config.globalProperties.$logs = logs;


userAction();
app.use(VanImage).use(Dialog).use(SwipeItem).use(Swipe).use(Popup).use(Tabs).use(Tab).use(Toast).use(Slider).use(store).use(router).use(Loading).use(Uploader).use(Button).use(Field).use(Icon).mount("#app");

Toast.setDefaultOptions({ duration: 600 });
