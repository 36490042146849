<!--
 * @Description: 创作中心页面，网格背景、工具等
 * @version: 
-->
<template>
  <div class="flex flex-col h-full w-full justify-center items-center">
    <!-- 网格背景 -->
    <div class="absolute editor-grid w-full h-full -z-10 grid grid-cols-18 divide-x divide-y">
      <div v-for="i in Array(648)" :key="i"></div>
    </div>
    <!-- <button style="z-index: 100000" @click="handleClick">dianji</button> -->

    <!-- 画布主体区域 -->
    <EditorContainer />

    <div class="rounded-t-xl bg-white z-20" :class="$route.name === 'savePreview' ? 'absolute h-screen left-0 top-0 w-full' : ''">
      <router-view v-slot="{ Component, route }">
        <keep-alive>
          <component :key="getFirstLevelRoute(route).name" v-if="getFirstLevelRoute(route).meta.keepAlive" :is="Component"></component>
        </keep-alive>
        <component :is="Component" :key="getFirstLevelRoute(route).name" v-if="!getFirstLevelRoute(route).meta.keepAlive" />
      </router-view>
    </div>
    <carrier-info></carrier-info>
    <search-box></search-box>
    <!-- <div :class="{ course_container: activeShow }" class="mask_content">
      <course-mask @closeShow="handleClick"></course-mask>
    </div> -->

    <!-- add tips -->
    <div v-if="isArtist && isArtistTipsShow" class="fixed left-0 top-0 w-full h-full z-30 bg-[#3f3f3f]  opacity-90" @click="onCloseTips" data-tip="artist">
      <div class="absolute left-0 bottom-48 w-full">
        <img src="@/static/tips-ip.png" alt="tips" />
      </div>
    </div>
    <div v-if="!isArtist && isStickerTipsShow" class="fixed left-0 top-0 w-full h-full z-30 bg-[#3f3f3f]  opacity-[1]" @click="onCloseTips" data-tip="sticker">
      <img src="@/static/tips-full.png" alt="tips" class="absolute left-0 top-0 w-full h-full" />
      <!-- <div>
        <img src="@/static/tips-free-top.png" alt="tips" class="absolute left-0 top-6 w-full" />
        <img src="@/static/tips-free-2.png" alt="tips" class="absolute left-0 bottom-0 w-full" />
      </div> -->
    </div>

  </div>
</template>

<script>

let userTips = null;
let tipStatus = localStorage.getItem("userTips");
if (tipStatus) {
  localStorage.getItem("userTips", true);
  userTips = true;
} else {
  userTips = false;
}

import courseMask from "@/components/content/courseMask/courseMask.vue";
import searchBox from "@/components/content/searchBox/searchBox.vue";
import carrierInfo from "@/components/content/carrierInfo/carrierInfo.vue";
import EditorContainer from "@/components/EditorContainer.vue";
import MenuContainer from "@/components/MenuContainer.vue";

export default {
  name: "HomeView",
  data() {
    return {
      activeShow: userTips,
      isArtist: false,
      isArtistTipsShow: true,
      isStickerTipsShow: true
    };
  },
  mounted(){
    this.isArtist = (this.$route.matched[1].path.indexOf("artist") > -1);
    
    // 获取引导提示层状态，只第一次显示
    if (this.isArtist) {
      if(localStorage.getItem('isArtistTipsShow')){
        this.isArtistTipsShow = false
      }else{
        this.isArtistTipsShow = true
      }
    }else{
      if(localStorage.getItem('isStickerTipsShow')){
        this.isStickerTipsShow = false
      }else{
        this.isStickerTipsShow = true
      }
    }
    
    
  },
  methods: {
    handleClick() {
      this.activeShow = !this.activeShow;
    },
    getFirstLevelRoute(route) {
      if (!Array.isArray(route.matched) || route.matched.length === 0) {
        return route;
      }
      return route.matched[0];
    },
    onCloseTips(){
      if(this.isArtist){
        this.isArtistTipsShow = false
        // 将tips缓存在localstorage中
        localStorage.setItem('isArtistTipsShow',true)
      }else{
        this.isStickerTipsShow = false
        localStorage.setItem('isStickerTipsShow', true)
      }
      
    }
  },
  components: {
    courseMask,
    searchBox,
    EditorContainer,
    MenuContainer,
    carrierInfo,
  },
};
</script>

<style lang="scss">
.mask_content {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.course_container {
  display: none;
}
</style>
