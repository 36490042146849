<!--
 * @Description: 画布主题区域，呈现载体和贴纸创作的主体区域
 * @version: 
-->
<template>
  <div class="w-full h-full flex justify-center items-center relative editor-container">
    <!-- <div ref="slider" class="keen-slider h-full w-full">
      <EditorCanvas v-for="(item, index) in perspectives" class="keen-slider__slide" :class="'number-slide' + (index + 1)" :key="item.key" :canvasId="item.key" :perspective="item.key" />
    </div> -->

    <van-swipe ref="slider" class="w-full h-full" :touchable="false" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in perspectives" :key="index" class="w-full h-full">
        <EditorCanvas :canvasId="item.key" :perspective="item.key" />
      </van-swipe-item>
    </van-swipe>

    <ToolBar @switchPerspective="switchPerspective" />

    <ColorPicker />

    <!-- 【ID1000375】【创作中心UI界面】顶部button有点靠下，距离顶部bar为12 -->
    <div v-if="!mcId" class="absolute right-3 top-[1rem] h-[1.875rem] justify-between min-w-[4.375rem]">
      <!-- <button class="text-xs px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="switchRoute">
        <div class="leading-5">{{ titleView }}</div>
        <img class="object-contain w-4" src="@/static/exchange_series.png" alt="" />
      </button> -->

      <!-- <button v-if="fromAvatar"
        class="text-xs px-3 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center"
        @click="editAvatar">
        <img class="w-4 h-4" :src="imgIcon" />
        <div class="leading-4">修改头像</div>
      </button> -->
      <button class="text-sm h-[1.875rem] px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="goToSavePreview">
        <div class="leading-5" :class="{ 'opacity-[0.35]': isDisabled }">完成设计</div>
        <img class="rotate-90 w-[0.7rem]" :class="{ 'opacity-[0.35]': isDisabled }" src="@/static/arrow_top.png" alt="" />
      </button>
    </div>

    <!-- <div v-else class="absolute left-1/2 -translate-x-1/2 top-3 flex w-full justify-between px-3">
      <button class="text-xs px-3 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="editAvatar">
        <img class="w-4 h-4" :src="imgIcon" />
        <div class="leading-4">修改头像</div>
      </button>
      <button class="text-xs px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="goToSavePreview">
        <div class="leading-5">完成设计</div>
        <img class="w-2 rotate-90" src="@/static/arrow_top.png" alt="" />
      </button>
    </div> -->
    <button
      v-else
      class="min-w-[4.375rem] h-[1.875rem] text-sm px-4 py-1 leading-4 rounded-full bg-white absolute right-3 top-[1rem] flex space-x-1 items-center justify-center"
      @click="goToSavePreview"
    >
      <div class="leading-5">完成设计</div>
      <img class="w-2 rotate-90 w-[0.7rem]" src="@/static/arrow_top.png" alt="" />
    </button>

    <!-- 载体 -->
    <!-- <div class="absolute left-3 bottom-3 flex justify-between">
      <div v-show="$store.getters.getCarrierConfigSize" class="absolute text-[10px] text-[#D6B67D] left-1/2 -translate-x-1/2 bottom-8 w-full text-center">
        规格:{{ $store.getters.getCarrierConfigSize }}
      </div>
      <button :class="{ 'active-btn': mcId }" class="text-xs px-4 py-1 leading-4 rounded-full bg-[#D6B67D] flex space-x-1 items-center justify-center" @click="switchCarrier">
        <div class="leading-5 text-whiteMain">更改载体 ></div>
      </button>
    </div> -->
    <!-- navigation pop -->

    <!-- 更改载体、艺术家模式切换弹出层 -->
    <van-popup
      v-model:show="showNavPop"
      round
      position="center"
      class="w-[21.4375rem] overflow-visible py-[1rem]"
      :style="{ background: '#FAF6EA', top: 'calc(50% - 2.8rem)' }"
      :overlay-style="{ background: 'f3f3f350' }"
      @close="onCloseNavPop"
    >
      <NavigationPop :isArtist="isArtist" />
      <div class="absolute w-[2rem] left-1/2 -bottom-[3.25rem] -translate-x-1/2 rounded-full overflow-hidden" @click="onCloseNavPop">
        <img src="@/static/close.jpg" />
      </div>
    </van-popup>
  </div>

  <!-- <canvas-share class="relative bottom-[-100%] h-0" ref="posterTarget" :showPopupStatus="showPopup"></canvas-share> -->

  <!-- 海报生成的canvas -->
  <canvas-share class="relative bottom-[-100%] h-0" ref="posterTarget" :showPopupStatus="false"></canvas-share>
  <!-- 封神活动海报 -->
  <canvas-share-activity class="relative bottom-[-100%] h-0" ref="posterTargetActivity" :showPopupStatus="false"></canvas-share-activity>
  
  <!-- https://mp-oss.tootools.cn/attach/2023/07/cffdc202307051837149794.jpg -->
  <!-- <cropper url="https://mer-oss.tootools.cn/def/19e61202307071457531296.png"></cropper> -->
  <!-- <cropper url="https://mp-oss.tootools.cn/attach/2023/07/cffdc202307051837149794.jpg"></cropper> -->
</template>
<script>
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import canvasShare from "@/components/content/canvasShare/index.vue";
import canvasShareActivity from "@/components/content/canvasShare/canvas-share-activity.vue";

// import { bindShareDesignPoster } from "@/api/app";
import "keen-slider/keen-slider.min.css";
import b1 from "@/static/buddha_icon.png";
import EditorCanvas from "@/components/EditorCanvas.vue";
import ToolBar from "./ToolBar.vue";
import NavigationPop from "./NavigationPop.vue";
import ColorPicker from "./ColorPicker.vue";
import { Dialog, Toast } from "vant";

import { getItem } from "@/utils/utils";

import cropper from "./Cropper/Cropper.vue";

export default {
  data() {
    return {
      showPopup: false,
      fromAvatar: false, //是否从头像页面过来
      imgIcon: b1, // icon图片地址
      titleView: "", // 入口名
      isArtist: false, //是否为艺术家
    };
  },
  watch: {
    $route: {
      handler: async function (val) {
        // console.log("watch打印当前的路由", val);
        if (val.path.indexOf("/artist") > -1) {
          this.titleView = "切换官方自制";
        } else {
          this.titleView = "切换艺术家系列";
        }
        this.fromAvatar = this.$route.query.fromAvatar;
      },
      immediate: true,
      // 深度观察监听
      // deep: true,
    },
  },
  async created() {
    console.log(this.templateId, this.productId, "---------");

    if (this.templateId || this.productId) {
      await this.fetchDesignJSON();
      this.$bus.$emit("loadFromTemplate");
    }

    await this.fetchFontList();
  },
  methods: {
    ...mapMutations(["SET_COLOR_CONFIG", "CHANGE_MENU_STATUS", "CARRIER_SWITCH_STATUS", "SET_SHARE_CANVAS_DATA", "SET_SHARE_POSTER_IMG", "SET_SHOW_NAV_POP"]),
    ...mapActions(["fetchColorLibrary", "fetchCarrierConfig", "fetchDesignJSON", "fetchFontList", "postProductSavePosterActions"]),
    // 路由切换 艺术家 和官方
    switchRoute() {
      let currentRoute = this.$route.path;
      console.log("切换路由", currentRoute);
      Dialog.confirm({
        title: "注意!",
        message: `切换至${this.titleView},会清空当前设计。`,
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          //【ID1000216】4.5 创作中心-用户-切换模式
          if (currentRoute.indexOf("/sticker") > -1) {
            this.$router.replace({
              path: "/artist/artist1",
            });
          } else if (currentRoute.indexOf("/artist") > -1) {
            this.$router.replace({
              path: "/sticker",
            });
          }
          window._hmt.push(["_trackEvent", "seriesSwitch", "switchSeries"]);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);

          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },

    getColorList() {
      const colorConfig = { ...this.getColorConfig };
      colorConfig.defaultColor = {
        ...colorConfig.defaultColor,
        ...this.getColorLibrary.filter(item => item.id.toString() === this.carrierConfig.color_id)[0],
      } || {
        color: "",
        id: "",
        type: "",
        title: "",
        isLocked: true,
      };
      this.SET_COLOR_CONFIG(colorConfig);
    },
    async goToSavePreview() {
      // console.time("goToSavePreview");
      // console.log(this.carrierConfig, this.carrierConfig?.right, "-------------");
      // 如果未做任何设计，不可提交
      if (Object.values(this.objectIsEmpty).indexOf(false) === -1) {
        Toast("请至少设计一个面");
        return;
      }
      Toast.loading({
        message: "正在生成设计...",
        forbidClick: true,
        duration: 0,
      });

      // 待解决: 这一步的目的
      if (this.carrierConfig?.left && this.carrierConfig?.right) {
        // this.$bus.$emit("left:exportPerspectiveCanvas");
        // this.$bus.$emit("right:exportPerspectiveCanvas");
        // this.$bus.$emit("front:renderPerspectiveCanvas");
        // this.$bus.$emit("back:renderPerspectiveCanvas");
      }
      // console.log("goToSavePreview");
      this.$bus.$emit("hideMenuPopup");

      // this.$bus.$emit(this.$store.state.currentPerspective + ":renderAllPerspectiveCanvas");
      // this.$bus.$emit(this.$store.state.currentPerspective + ":zoomIn");

      // console.time("exportCanvasTime");
      for (let i = 0; i < this.perspectives.length; i++) {
        await new Promise(resolve => {
          this.$bus.$emit(this.perspectives[i].key + ":renderAllPerspectiveCanvas", resolve);
        });
      }
      // console.timeEnd("exportCanvasTime");

      // TODO: 完成设计调用接口 获取制作分享海报
      // console.time("postProductSavePosterActions");
      // 瓶颈函数
      try {
        let res = await this.postProductSavePosterActions(4);
        if (res == undefined) return;
        
        this.SET_SHARE_CANVAS_DATA(res);
        // console.timeEnd("postProductSavePosterActions");

        // 海报制作移到保存预览页面
        // console.time("posterImg");
        console.log("生成海报的数据，canvas res--->", res);

        // note: 2023-07-10需求，创作中心的分享海报功能隐藏，在小程序中去显示和生成自定义海报。 
        let { cover_img, poster_bg_img, qrcode, product_id } = res;
        if (!cover_img || !poster_bg_img || !qrcode || product_id == undefined || product_id == null) return Toast("海报数据不完整！");

        // 生成海报
        let posterImg = "";
        if (this.hasActivityCateId) {
          // 封神活动海报
          posterImg = await this.$refs.posterTargetActivity.initCanvas({ ...res, avatar: '', name: '' });
        } else {
          // 普通海报
          posterImg = await this.$refs.posterTarget.initCanvas(res);
        };

        console.log("生成的海报", posterImg);

      } catch (err) {
        console.log(err);
        Toast.clear();
      }

      // console.timeEnd("posterImg");

      Toast.clear();

      this.$router.push({
        name: "savePreview",
        query: {
          isArtist: this.$route.matched[1].path.indexOf("artist") > -1,
        },
      });
      window._hmt.push(["_trackEvent", "completeButton", "Complete"]);
      // console.timeEnd("goToSavePreview");
    },
    editAvatar() {
      Dialog.confirm({
        message: "修改头像后会丢失当前设计，确定继续？",
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          this.$router.back();
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    switchPerspective(e) {
      this.$refs.slider.swipeTo(e);
    },
    saveSnapshot() {
      this.$bus.$emit(this.currentPerspective + ":saveSnapshot");
    },
    // initPerspective() {
    //   const perspectives = ["front", "back", "left", "right"];
    //   perspectives.forEach((item, index) => {
    //     if (!this.carrierConfig[item]) {
    //       return;
    //     }
    //     this.perspectives.push({
    //       index,
    //       key: item,
    //       ...this.carrierConfig[item],
    //     });
    //   });
    // },
    onCloseNavPop() {
      this.SET_SHOW_NAV_POP(false);
    },
  
  },

  async mounted() {
    await this.fetchColorLibrary();
    await this.fetchCarrierConfig();
    this.getColorList();
    this.fromAvatar = this.$route.query.fromAvatar;
    this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1;
    // this.$nextTick(() => {
    //   this.slider = new KeenSlider(this.$refs.slider, {
    //     drag: false,
    //     slides: this.perspectives.length,
    //   });
    // });
  },
  activated() {
    this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1;
  },
  computed: {
    ...mapGetters(["getColorConfig", "getColorId", "getColorLibrary"]),
    ...mapState(["currentPerspective", "carrierConfig", "mcId", "mcConfig", "menuStatus", "templateId", "productId", "designJSON", "objectIsEmpty", "showNavPop"]),
    perspectives() {
      // 处理每个面的数据,以及该载体拥有哪些面
      const perspectiveKeys = ["front", "back", "left", "right"];
      let perspectiveArr = [];

      perspectiveKeys.forEach((item, index) => {
        if (!this.carrierConfig[item]) {
          return;
        }
        perspectiveArr.push({
          index,
          key: item,
          ...this.carrierConfig[item],
        });
      });

      return perspectiveArr;
    },
    isDisabled() {
      // return Object.values(this.objectIsEmpty).indexOf(false) === -1
      return false;
    },
    hasActivityCateId() {
      return getItem("activityCateId") || false;
    },
  },
  components: {
    canvasShare,
    canvasShareActivity,
    EditorCanvas,
    ToolBar,
    NavigationPop,
    ColorPicker,
    cropper
  },
};
</script>

<style>
.active-btn {
  bottom: 32px !important;
}

.editor-container {
  /* background: #f0f0f0; */
}

.editor-grid {
  background: #f0f0f0;
}
</style>
