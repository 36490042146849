<template>
  <div class="container">
    <div :class="[{ show: isShow }]">
      <div class="mask"></div>
      <div class="content">
        <div @click="closeX" class="next_icon">
          <img src="@/static/close.jpg" alt="" />
        </div>
        <van-swipe
          lazy-render
          :loop="false"
          @change="getCurrentIndex"
          ref="swipeLL"
          class="my-swipe"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in swiperList" :key="index">
            <img class="course_main" :src="item" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div
          v-if="currentIndex === 0 || currentIndex === 1"
          @click="nextStep"
          class="button"
        >
          下一步
        </div>
        <div v-if="currentIndex === 2" @click="nextStep" class="button">
          完成
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import s1 from "@/static/course_main_1.png";
import s2 from "@/static/course_main_2.png";
import s3 from "@/static/course_main_3.png";
export default {
  data() {
    return {
      swiperList: [s1, s2, s3],
      currentIndex: 0
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  methods: {
    nextStep() {
      if (this.currentIndex >= 2) {
        localStorage.setItem("userTips", "false");
        return this.$emit("closeShow");
      }
      this.currentIndex += 1;
      this.$refs.swipeLL.swipeTo(this.currentIndex);
    },
    getCurrentIndex(index) {
      this.currentIndex = index;
    },
    closeX() {
      localStorage.setItem("userTips", "false");
      return this.$emit("closeShow");
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  .show .mask {
    z-index: 888;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #2e2e2e;
    opacity: 0.3;
  }
  .show .content {
    // opacity: 1;
    box-sizing: border-box;
    z-index: 999;
    position: absolute;
    height: 471px;
    width: 300px;
    transform: translate(-50%, -56.5%);
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 8px;
    // display: flex;

    .button {
      font-size: 12px;
      position: absolute;
      bottom: -50px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      width: 72px;
      height: 30px;
      border-radius: 25px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
    }
    .my-swipe {
      // background-color: aqua;
      border-radius: 8px;
      overflow: hidden;
      height: 471px;
      width: 300px;
      .course_main {
        height: 471px;
        width: 300px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
      }
    }

    .next_icon {
      z-index: 20;
      position: absolute;
      top: -30px;
      right: -20px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}
</style>
