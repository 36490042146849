<template>
  <div class="scroll-wrapper" ref="scroll">
    <div ref="scroll_content" class="scroll-content">
      <slot></slot>
      <template v-if="isShow && limitSwitch">
        <div v-if="!isDone" class="pullup-tips">
          <div v-if="!pullUpLoadStatus" class="before-trigger">
            <span class="pullup-txt">Pull up and load more</span>
          </div>
          <div v-else class="after-trigger">
            <span class="pullup-txt">Loading...</span>
          </div>
        </div>
        <div v-else class="pullup-tips">
          <div class="after-trigger">
            <span class="pullup-txt">at the end</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import BScroll from '@better-scroll/core'
import Pullup from '@better-scroll/pull-up'
import ObserveDOM from '@better-scroll/observe-dom'
BScroll.use(ObserveDOM)
  BScroll.use(Pullup)
export default {
  data () {
    return {
      rightItemArray:[],
      pullUpLoadStatus: false,
      isNoDate:true
    }
  },
  props:{
    probeType :{
      type:Number,
      default () {
        return 3
      }

    },
     pullType:{
      type:Boolean,
      default:true
    },
    isPullUpLoad:{
      type:Boolean,
      default:true
    },
    isDone:{
      type:Boolean,
      default : false
    },
    isShow:{
      type:Boolean,
      default:false
    },
    limitSwitch:{
      type:Boolean,
      default () {
        return false
      }
    }

  },
  mounted() {
    this.init()
    this.$nextTick(()=>{
      console.log('Refresh');

      this.refresh()
    })
  },
  beforeUnmount() {
    this.bs.destroy()
  },
  methods: {
    // debounce ( fuc , time) {
    //   let timer;
    //   return function () {
    //     if (timer)  clearTimeout(timer)
    //     let args = arguments
    //     timer = setTimeout(() => {
    //       fuc.apply(this,args )
    //     }, time);
    //   }
    // },
    init() {
      this.$nextTick(() => {
        this.bs = new BScroll(this.$refs.scroll, {
        probeType: this.probeType,
        bounce:true,
        click: this.isPullUpLoad,
        scrollX:false,
        freeScroll:true,
        eventPassthrough: 'horizontal',
        observeDOM: true,
        pullUpLoad:true
      })
      this.bs.on('scrollStart', () => {
      })
      this.bs.on('scroll', (pos) => {
        this.$emit('scrollH', pos)
      })
      this.bs.on('scrollEnd', (pos) => {
      })

      //监听上拉刷新
       this.bs.on('pullingUp', ()=> {
        console.log('pullingUp');

        this.pullUpLoadStatus = true
        //发送事件加载数据
        this.$emit('LoadMore')

       })

      })

    },
    //完成加载
    finishUp () {
       this.bs.finishPullUp()
    },
    //开始加载
    openPullUp () {
       this.bs.openPullUp()
    },
    clickHandler (item) {
      window.alert(item)
    },
    //刷新高度
    refresh() {
      this.$nextTick(() => {
      this.bs && this.bs.refresh();

      })
    },
    scrollToInit () {
     this.bs &&  this.bs.scrollTo(0 , 0)
    },
    scrollTo (height) {
      this.bs.scrollTo(0,-height , 200, 'easing')
    },
     scrollToLeft (height) {
      console.log('跳转到指定位置');

      this.bs.scrollTo(0,-height , 150, 'easing')
      setTimeout(() => {
        this.bs.stop()
      },150)
    },
    scrollToElement (el) {
      this.bs.scrollToElement(el, 300, 0,true, 'easing')
    },

  }
}
</script>
<style lang="scss" scoped>
.scroll-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.pullup-tips {
  padding: 20px;
  text-align: center;
  color: #999;
}
</style>
